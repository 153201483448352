// import "./src/assets/print.css"
// import "./src/assets/typography.css";
// import "./src/assets/header.scss";
// import "./src/assets/social-menu.scss";
// import "./src/assets/menu.scss";
// import "./src/assets/layout.scss";
// import "./src/assets/hamburger.scss";
import "./src/assets/footer.scss";
// import "./src/assets/portfolio-grid.scss";
// import "./src/assets/portfolio-feed.scss";
// import "./src/assets/featured-media-background.scss";
// import "./src/assets/feature-section.scss";
// import "./src/assets/home.scss";
// import "./src/assets/forms.scss";
// import "./src/assets/grid.scss";
// import "./src/assets/article-card.scss";
// import "./src/assets/featured-row.scss";
// import "./src/assets/articles-grid.scss";
// import "bootstrap/dist/css/bootstrap.min.css";

// import "../../assets/article.scss";
// import "../../assets/base.scss";
// import "../../assets/cta.scss";
// import "../../assets/social-menu.scss";
// import "../../assets/avatar.scss";
// import "../../assets/banner.scss";
// import "../../assets/buttons.scss";
import "./src/assets/global.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer");
  }
};
