exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reviews-fruitland-js": () => import("./../../../src/pages/reviews-fruitland.js" /* webpackChunkName: "component---src-pages-reviews-fruitland-js" */),
  "component---src-pages-reviews-jerome-js": () => import("./../../../src/pages/reviews-jerome.js" /* webpackChunkName: "component---src-pages-reviews-jerome-js" */),
  "component---src-templates-types-new-article-js": () => import("./../../../src/templates/types/newArticle.js" /* webpackChunkName: "component---src-templates-types-new-article-js" */),
  "component---src-templates-types-page-js": () => import("./../../../src/templates/types/page.js" /* webpackChunkName: "component---src-templates-types-page-js" */)
}

